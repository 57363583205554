import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Card, Button,
  Icon, Tag, Alert,
  Upload, Row, Col, Skeleton,
} from 'antd'
import history from 'utils/history'
import ModalPreviewDocument from '../modalPreview'
import ModalConfirmClaim from '../modalConfirm'
import ModalRejectClaim from '../modalRejectClaim'

const DetailCustomerClaimDeath = ({
  detailClaim, isSubmit,
  modalPreview, setModalPreview,
  isExport, handleDownloadClaimDoc,
  scrollToTop, documentClaim, handleChangeDocumentClaim,
  handleValidateDocumentClaim, handleUpdateStatusClaim,
  handleUpdateRollbackClaim,
  modalConfirmClaim, setModalConfirmClaim,
  rejectClaimModal, setRejectClaimModal,
  handleFullRejectClaim, handleChangeRejectNotes,
}) => {
  const naik = useRef(null)
  if (scrollToTop) {
    naik.current.scrollIntoView({ behavior: 'smooth' })
  }
  const statusClaim = detailClaim.data && detailClaim.data.status

  let tagColor
  switch (detailClaim?.data?.status && detailClaim?.data?.status?.name) {
    case 'document review':
      tagColor = 'orange'
      break
    case 'need review':
      tagColor = 'blue'
      break
    case 'approved':
      tagColor = 'green'
      break
    case 'rejected':
      tagColor = 'red'
      break
    case 'full rejection':
      tagColor = 'red'
      break
    case 'administration':
      tagColor = 'orange'
      break
    case 'fund transferred':
      tagColor = 'green'
      break
    default:
      tagColor = ''
  }

  let alertStatus
  if (statusClaim && statusClaim.name === 'rejected') {
    alertStatus = {
      message: 'Permohonan dokumen telah ditolak, Form pengajaun dapat direview kembali setelah peserta sudah memperbaharui dokumen yang tidak sesuai',
      type: 'error',
    }
  } else if (statusClaim && statusClaim.name === 'approved') {
    alertStatus = {
      message: "Dokumen telah lengkap dan sudah valid, klik 'Simpan' untuk menyetujui permohonan klaim",
      type: 'info',
    }
  } else if (statusClaim && statusClaim.name === 'administration') {
    alertStatus = {
      message: "Permohonan klaim telah disetujui, klik 'Proses Pengiriman Dana' untuk melakukan Transfer",
      type: 'info',
    }
  } else if (statusClaim && statusClaim.name === 'rejected') {
    alertStatus = {
      message: detailClaim.data.reviewer && detailClaim.data.reviewer.review_notes ? detailClaim.data.reviewer.review_notes : 'Rejected',
      type: 'error',
    }
  } else if (statusClaim && statusClaim.name === 'full rejection') {
    alertStatus = {
      message: detailClaim.data.reviewer && detailClaim.data.reviewer.review_notes ? detailClaim.data.reviewer.review_notes : 'Full Rejected',
      type: 'error',
    }
  }
  return (
    <div className="pb-5">
      <div ref={naik} />
      <div className="mb-3">
        {alertStatus && (
          <Alert
            message={alertStatus.message || ''}
            type={alertStatus.type || ''}
            closable
          />
        )}
      </div>

      <div className="d-flex justify-content-end align-items-center">
        {statusClaim && (statusClaim?.name?.toUpperCase() === 'INQUIRY' || statusClaim?.name?.toUpperCase() === 'NEED REVIEW') && (
        <div>
          <Button
            loading={isSubmit}
            className="button-primary"
            onClick={() => setModalConfirmClaim({ isOpen: true, type: 'status', status: 'document review' })}
          >
            Lihat Klaim
          </Button>
        </div>
        )}
        {statusClaim && (statusClaim?.name?.toUpperCase() !== 'INQUIRY' || statusClaim?.name?.toUpperCase() !== 'NEED REVIEW') && (
          <div>
            <Button
              loading={isExport}
              className="button-primary ml-2"
              onClick={() => handleDownloadClaimDoc(detailClaim?.data?.account_name || '')}
            >
              Unduh Dokumen Klaim
            </Button>
          </div>
        )}
      </div>

      <div className="custom-card-wrapper mt-3 mb-4">
        {detailClaim?.isLoading
          ? (
            <Row gutter={[18, 12]}>
              <Col span={16}>
                <Card bordered={false}>
                  <h5 className="fw-bold mb-3">
                    Data Peserta
                  </h5>
                  <Skeleton active />
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false}>
                  <h6 className="fw-bold mb-3">
                    Dokumen Persyaratan
                  </h6>
                  <Skeleton active />
                </Card>
              </Col>
            </Row>
          ) : (
            <Row gutter={[18, 12]}>
              <Col span={16}>
                <Card bordered={false}>
                  <h5 className="fw-bold mb-3">
                    Data Peserta
                  </h5>
                  <Row gutter={[16, 12]}>
                    <Col span={8}>
                      <h6 className="mb-2">Tanggal Permohonan</h6>
                      <p>{detailClaim?.data?.created_at || '-'}</p>
                    </Col>
                    <Col span={8}>
                      <h6 className="mb-2">Nama Peserta</h6>
                      <p>{detailClaim?.data?.account_name || '-'}</p>
                    </Col>
                    <Col span={8}>
                      <h6 className="mb-2">Jenis Klaim</h6>
                      <p>{detailClaim?.data?.claim_type ? detailClaim?.data?.claim_type?.name : '-'}</p>
                    </Col>
                    <Col span={8}>
                      <h6 className="mb-2">Nomor Peserta</h6>
                      <p>{detailClaim?.data?.registered_number || '-'}</p>
                    </Col>
                    <Col span={8}>
                      <h6 className="mb-2">Tanggal Bergabung</h6>
                      <p>{detailClaim?.data?.join_date || '-'}</p>
                    </Col>
                    <Col span={8}>
                      <h6 className="mb-2">Status Permohonan</h6>
                      <Tag color={tagColor} style={{ fontSize: '0.8rem' }}>
                        {statusClaim && statusClaim?.status_label ? statusClaim?.status_label : '-'}
                      </Tag>
                    </Col>
                  </Row>

                  {detailClaim?.data?.reviewer?.review_notes !== '' && (
                    <Row>
                      <Col span={8}>
                        <h6 className="mb-2">Alasan Penolakan</h6>
                        <p>{detailClaim?.data?.reviewer?.review_notes || '-'}</p>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>

              <Col span={8}>
                <Card bordered={false}>
                  <h6 className="fw-bold mb-3">
                    Dokumen Persyaratan
                  </h6>
                  {detailClaim.data?.documents?.length > 0
                    ? (
                      <div>
                        {detailClaim.data.documents.map(item => (
                          <div className="d-flex align-items-center mt-3" key={item?.id}>
                            <Button
                              className="button-second-primary d-flex align-items-center"
                              style={{ cursor: 'pointer' }}
                              onClick={() => setModalPreview({ isOpen: true, data: item?.url || '' })
                             }
                            >
                              <Icon type="file-image" />
                              <span>Lihat File</span>
                            </Button>
                            {item?.url
                         && (
                         <Button
                           className="button-primary d-flex align-items-center ml-2"
                           href={item?.url}
                           target="_blank"
                           download={item?.url ? item?.url?.slice(item?.url?.lastIndexOf('/') + 1) : 'file'}
                         >
                           <Icon type="download" />
                           <span>Download</span>
                         </Button>
                         )}
                          </div>
                        ))}
                      </div>
                  )
                    : (
                      <div>
                        <Upload
                          disabled={isSubmit}
                          name="document"
                          listType="picture"
                          beforeUpload={() => false}
                          onChange={e => handleChangeDocumentClaim(e)}
                          fileList={documentClaim}
                          accept="image/*,.pdf"
                        >
                          <Button
                            loading={isSubmit}
                            className="button-primary"
                          >
                            Upload Dokumen Persyaratan Disini
                          </Button>
                        </Upload>
                      </div>
                    )}
                </Card>
              </Col>
            </Row>
          )}
      </div>

      <div className="custom-card-wrapper mt-3 mb-4">
        {detailClaim?.isLoading
          ? (
            <Row>
              <Col span={16}>
                <Card bordered={false}>
                  <h5 className="fw-bold mb-3">
                    Data Pemohon
                  </h5>
                  <Skeleton active />
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={16}>
                <Card bordered={false}>
                  <h5 className="fw-bold mb-3">
                    Data Pemohon
                  </h5>
                  <Row>
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Nama Pemohon</h6>
                    </Col>
                    <Col span={8}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.name || '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Email Pemohon</h6>
                    </Col>
                    <Col span={8}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.email || '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Hubungan</h6>
                    </Col>
                    <Col span={8}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.relation_type ? detailClaim?.data?.relation_type?.name : '-'}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
      </div>

      <div className="custom-card-wrapper mt-3 mb-4">
        {detailClaim?.isLoading ? (
          <Row>
            <Col span={16}>
              <Card bordered={false}>
                <h5 className="fw-bold mb-3">
                  Data Penerima Manfaat
                </h5>
                <Skeleton active />
              </Card>
            </Col>
          </Row>
          ) : (
            <Row>
              <Col span={16}>
                <Card bordered={false}>
                  <h5 className="fw-bold mb-3">
                    Data Penerima Manfaat
                  </h5>
                  <Row>
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Nama Lengkap</h6>
                    </Col>
                    <Col span={8}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.recipient_name || '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Bank</h6>
                    </Col>
                    <Col span={8}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.bank ? detailClaim?.data?.bank?.bank_name : '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Nomor Rekening</h6>
                    </Col>
                    <Col span={8}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.bank ? detailClaim?.data?.bank?.bank_account_no : '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Email</h6>
                    </Col>
                    <Col span={8}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.recipient_email || '-'}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
      </div>

      <Row>
        <Col span={16}>
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-between">
              <Button
                className="button-second-primary"
                onClick={() => history.push('/claim/claim-by-admin')}
              >
                Kembali
              </Button>

              {statusClaim
                && (statusClaim?.name?.toUpperCase() === 'INQUIRY'
                || statusClaim?.name?.toUpperCase() === 'DOCUMENT REVIEW'
                || statusClaim?.name?.toUpperCase() === 'NEED REVIEW'
                || statusClaim?.name?.toUpperCase() === 'APPROVED'
                || statusClaim?.name?.toUpperCase() === 'ADMINISTRATION')
                && (
                  <Button
                    className="button-danger ml-2"
                    onClick={() => setRejectClaimModal(true)}
                  >
                    Batalkan Klaim
                  </Button>
                )}
              {statusClaim
                && (statusClaim?.name?.toUpperCase() === 'APPROVED'
                || statusClaim?.name?.toUpperCase() === 'ADMINISTRATION')
                && (
                  <Button
                    className="button-custom btn-yellow ml-2"
                    style={{ color: '#000' }}
                    onClick={() => setModalConfirmClaim({ isOpen: true, type: 'rollback', status: '' })}
                  >
                    Pengembalian Status Klaim
                  </Button>
                )}
              {(statusClaim && statusClaim?.name?.toUpperCase() === 'DOCUMENT REVIEW')
                && (
                  <Button
                    className="button-primary ml-2"
                    onClick={() => setModalConfirmClaim({ isOpen: true, type: 'validation', status: '' })}
                  >
                    Simpan
                  </Button>
                )
              }
              {(statusClaim && statusClaim?.name?.toUpperCase() === 'APPROVED')
                && (
                <Button
                  className="button-custom btn-green ml-2"
                  onClick={() => setModalConfirmClaim({ isOpen: true, type: 'status', status: 'administration' })}
                >
                  Lanjut Proses Administrasi
                </Button>
                )
              }
              {(statusClaim && statusClaim?.name?.toUpperCase() === 'ADMINISTRATION')
                && (
                <Button
                  className="button-custom btn-green ml-2"
                  onClick={() => setModalConfirmClaim({ isOpen: true, type: 'status', status: 'fund transferred' })}
                >
                  Proses Pengiriman Dana
                </Button>
                )
              }
            </div>
          </div>
        </Col>
      </Row>

      {/* Modal Confirm Klaim */}
      <ModalConfirmClaim
        modalConfirm={modalConfirmClaim}
        setModalConfirm={setModalConfirmClaim}
        handleValidateDocumentClaim={handleValidateDocumentClaim}
        handleUpdateRollbackClaim={handleUpdateRollbackClaim}
        handleUpdateStatusClaim={handleUpdateStatusClaim}
      />

      {/* Modal Reject Klaim */}
      <ModalRejectClaim
        rejectClaimModal={rejectClaimModal}
        setRejectClaimModal={setRejectClaimModal}
        handleChangeRejectNotes={handleChangeRejectNotes}
        handleFullRejectClaim={handleFullRejectClaim}
      />

      {/* Modal Preview Dokumen */}
      <ModalPreviewDocument
        modalPreview={modalPreview}
        setModalPreview={setModalPreview}
      />
    </div>
  )
}

DetailCustomerClaimDeath.propTypes = {
  isExport: PropTypes.bool,
  detailClaim: PropTypes.object,
  modalPreview: PropTypes.object,
  setModalPreview: PropTypes.func,
  isSubmit: PropTypes.bool,
  scrollToTop: PropTypes.bool,
  documentClaim: PropTypes.array,
  handleChangeDocumentClaim: PropTypes.func,
  handleValidateDocumentClaim: PropTypes.func,
  handleUpdateRollbackClaim: PropTypes.func,
  handleUpdateStatusClaim: PropTypes.func,
  handleDownloadClaimDoc: PropTypes.func,
  modalConfirmClaim: PropTypes.object,
  setModalConfirmClaim: PropTypes.func,
  rejectClaimModal: PropTypes.bool,
  setRejectClaimModal: PropTypes.func,
  handleChangeRejectNotes: PropTypes.func,
  handleFullRejectClaim: PropTypes.func,
}

export default DetailCustomerClaimDeath
