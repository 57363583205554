import React from 'react'
import PropTypes from 'prop-types'
import {
  Row, Col, Input,
  Modal, Button,
} from 'antd'

const ModalConfirmClaim = ({
  isSubmit, rejectClaimModal, setRejectClaimModal,
  handleChangeRejectNotes, handleFullRejectClaim,
}) => (
  <div>
    <Modal
      centered
      visible={rejectClaimModal}
      footer={null}
      closable={false}
    >
      <div>
        <div className="text-center mb-2">
          <h5 style={{ fontWeight: 600, color: '#1b4db3' }}>
            Alasan Pembatalan Klaim
          </h5>
        </div>
        <Row>
          <Col>
            <Input.TextArea
              rows={8}
              onChange={e => handleChangeRejectNotes(e)}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-3">
          <div className="d-flex">
            <Button
              className="button-second-primary"
              onClick={() => setRejectClaimModal(false)}
            >
              Batal
            </Button>
            <Button
              loading={isSubmit}
              className="button-danger ml-2"
              onClick={() => handleFullRejectClaim()}
            >
              Tolak Klaim
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
)

ModalConfirmClaim.propTypes = {
  isSubmit: PropTypes.bool,
  rejectClaimModal: PropTypes.bool,
  setRejectClaimModal: PropTypes.func,
  handleChangeRejectNotes: PropTypes.func,
  handleFullRejectClaim: PropTypes.func,
}

export default ModalConfirmClaim
