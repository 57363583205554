import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { message } from 'antd'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  fetchDetailClaim, updateStatusClaim, updateRollbackClaim, checkDocumentValidateClaim,
  uploadBukitTransfer, exportDoc,
} from 'actions/Claim'
import Helper from 'utils/Helper'
import ClaimNormalDetail from 'components/pages/claim/claimNormal/detail'

const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailClaim: bindActionCreators(fetchDetailClaim, dispatch),
  updateStatusClaim: bindActionCreators(updateStatusClaim, dispatch),
  updateRollbackClaim: bindActionCreators(updateRollbackClaim, dispatch),
  checkDocumentValidateClaim: bindActionCreators(checkDocumentValidateClaim, dispatch),
  uploadBukitTransfer: bindActionCreators(uploadBukitTransfer, dispatch),
  exportDoc: bindActionCreators(exportDoc, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('detailClaim', 'setDetailClaim', {
    isLoading: false,
    data: {},
  }),
  withState('modalPreview', 'setModalPreview', {
    isOpen: false,
    data: '',
  }),
  withState('isSubmit', 'setIsSubmit', false),
  withState('scrollToTop', 'setScrollToTop', false),
  withState('uploadModal', 'setUploadModal', false),
  withState('isUploading', 'setIsUploading', false),
  withState('previewFile', 'setPreviewFile', ''),
  withState('fileName', 'setFileName', ''),
  withState('isExport', 'setIsExport', false),
  withState('documentList', 'setDocumentList', []),
  withState('statusClaim', 'setStatusClaim', {}),
  withState('rejectNotes', 'setRejectNotes', ''),
  withState('rejectClaimModal', 'setRejectClaimModal', false),
  withState('modalPaymentDocument', 'setModalPaymentDocument', false),
  withState('modalConfirmClaim', 'setModalConfirmClaim', {
    isOpen: false,
    type: '',
    status: '',
  }),
  withHandlers({
    handleScrollToTop: props => () => {
      props.setScrollToTop(true)
      setTimeout(() => {
        props.setScrollToTop(false)
      }, 5000)
    },
  }),
  withHandlers({
    fetchDetail: props => () => {
      const { match, setDocumentList } = props
      props.setDetailClaim({
        isLoading: true,
        data: {},
      })
      props.fetchDetailClaim(match?.params?.id || '')
        .then((res) => {
          props.setStatusClaim(res?.data?.status)
          props.setDetailClaim({
            isLoading: false,
            data: res?.data,
          })
          let documents = []
          // eslint-disable-next-line arrow-body-style
          documents = res?.data?.documents?.map((item) => {
            return {
              ...item,
              document_id: item?.id,
              is_approved: item.status?.toUpperCase() === 'APPROVED',
            }
          })
          setDocumentList(documents)
        })
        .catch((err) => {
          props.setDetailClaim({
            isLoading: false,
            data: {},
          })
          message.error(err?.message)
        })
    },
  }),
  withHandlers({
    toggleUploadModal: props => () => {
      props.setUploadModal(!props.uploadModal)
    },
    handleChangeRejectNotes: props => (e) => {
      props.setRejectNotes(e?.target?.value)
    },
    handleDownload: (link) => {
      const url = window.open(link, '_blank')
      return url.location
    },
    handleChangeDocumentStatus: props => (e, index) => {
      const { documentList, setDocumentList } = props
      const currentDoc = documentList
      if (e?.target?.checked === true) {
        currentDoc[index].is_approved = true
      } else {
        currentDoc[index].is_approved = false
      }
      setDocumentList(currentDoc)
    },
    handleChangeReason: props => (e, index) => {
      const { documentList, setDocumentList } = props
      const val = e?.target?.value
      const currentDoc = documentList

      currentDoc[index].reason = val
      setDocumentList(currentDoc)
    },
    handleDownloadClaimDoc: props => (name) => {
      const { match, setIsExport } = props
      setIsExport(true)
      props.exportDoc(match?.params?.id || '')
        .then((result) => {
          if (result) {
            const FileSaver = require('file-saver') // eslint-disable-line global-require
            const blob = new Blob([result.data], { type: 'application/pdf' })
            FileSaver.saveAs(blob, `claim_documents_${name}.pdf`)
            setIsExport(false)
          } else {
            message.error('Something when wrong')
          }
        }).catch((error) => {
          message.error(error)
          setIsExport(false)
        })
    },
    handleChangeFile: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)

      if (fileList.length > 0) {
        props.setFileName(info.file.name)
        return Helper.getBase64(info.file, file => props.setPreviewFile(file))
      }
      return false
    },
    handleUpload: props => () => {
      const {
        setIsUploading, fetchDetail,
        match, setPreviewFile, setUploadModal,
      } = props
      const payload = {
        document: props.previewFile,
      }
      setIsUploading(true)
      props.uploadBukitTransfer(payload, match.params.id).then(() => {
        fetchDetail()
        setPreviewFile('')
        setUploadModal(!props.uploadModal)
        setIsUploading(false)
        message.success('File has been uploaded').then(() => (
          setUploadModal(!props.uploadModal)
        ))
      }).catch((err) => {
        setIsUploading(false)
        message.error(err)
        setUploadModal(!props.uploadModal)
      })
    },
    handleFullRejectClaim: props => () => {
      const {
        match, setIsSubmit, setRejectClaimModal,
        rejectNotes, fetchDetail,
      } = props
      if (rejectNotes === '') {
        message.error('Alasan Pembatalan Klaim wajib diisi')
        return
      }
      const payload = {
        status: 'rejected',
        notes: rejectNotes,
      }
      setIsSubmit(true)
      props.updateStatusClaim(match?.params?.id, payload)
        .then(() => {
          setIsSubmit(false)
          setRejectClaimModal(false)
          fetchDetail()
          message.success('Permintaan Klaim Berhasil telah ditolak')
        })
        .catch((error) => {
          setIsSubmit(false)
          setRejectClaimModal(false)
          message.error(error)
        })
    },
    handleValidateDocumentClaim: props => () => {
      const {
        match, setIsSubmit, documentList, setModalConfirmClaim, fetchDetail,
      } = props
      const isNotComplete = documentList.filter(item => !item.is_approved && item.reason === '')

      if (isNotComplete?.length > 0) {
        message.error('Alasan penolakan kelengkapan dokumen wajib diisi')
      } else {
        setIsSubmit(true)
        props.checkDocumentValidateClaim(match?.params?.id, { document_validation: documentList })
          .then((result) => {
            setIsSubmit(false)
            fetchDetail()
            props.handleScrollToTop()
            message.success(result)
            setModalConfirmClaim({
              isOpen: false,
              type: '',
              status: '',
            })
          })
          .catch((error) => {
            setIsSubmit(false)
            message.error(error)
            setModalConfirmClaim({
              isOpen: false,
              type: '',
              status: '',
            })
          })
      }
    },
    handleUpdateRollbackClaim: props => () => {
      const { match, setIsSubmit, setModalConfirmClaim } = props
      setIsSubmit(true)
      props.updateRollbackClaim(match?.params?.id)
        .then(() => {
          setIsSubmit(false)
          props.fetchDetail()
          props.handleScrollToTop()
          setModalConfirmClaim({
            isOpen: false,
            type: '',
            status: '',
          })
          message.success('Berhasil Update Status Klaim')
        })
        .catch(() => {
          setIsSubmit(false)
          setModalConfirmClaim({
            isOpen: false,
            type: '',
            status: '',
          })
        })
    },
    handleUpdateStatusClaim: props => (val) => {
      const { match, setIsSubmit, setModalConfirmClaim } = props
      setIsSubmit(true)
      props.updateStatusClaim(match?.params?.id, { status: val })
        .then(() => {
          setIsSubmit(false)
          props.fetchDetail()
          props.handleScrollToTop()
          setModalConfirmClaim({
            isOpen: false,
            type: '',
            status: '',
          })
          message.success('Berhasil Update Status Klaim')
        })
        .catch((error) => {
          setIsSubmit(false)
          message.error(error)
          setModalConfirmClaim({
            isOpen: false,
            type: '',
            status: '',
          })
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Beranda', 'Pengajuan Klaim', 'Administrasi'])
      this.props.updateSiteConfiguration('titlePage', 'Detail Permohonan Klaim')
      this.props.fetchDetail()
    },
  }),
)(ClaimNormalDetail)
