import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal, Spin,
} from 'antd'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

const ModalPreviewDocument = ({
  modalPreview, setModalPreview,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }
  const typeFile = modalPreview?.data?.split('.')?.pop()?.split('?')[0]
  return (
    <div>
      <Modal
        title="Preview Dokumen"
        centered
        width={900}
        visible={modalPreview?.isOpen}
        footer={null}
        onCancel={() => setModalPreview({ isOpen: false, data: '' })}
      >
        <div>
          {modalPreview?.data === ''
            ? (
              <div className="text-center">
                <Spin />
              </div>
            )
            : (
              <SimpleReactLightbox>
                <SRLWrapper options={lightBoxSetting}>
                  {typeFile === 'pdf' ? (
                    <iframe
                      title="preview-document"
                      className="pdf-frame"
                      src={modalPreview?.data}
                    />
                  )
                    : (
                      <img
                        className="img-fluid"
                        src={modalPreview.data}
                        alt="document-img"
                      />
                    )}
                </SRLWrapper>
              </SimpleReactLightbox>
            )}
        </div>
      </Modal>
    </div>
  )
}

ModalPreviewDocument.propTypes = {
  modalPreview: PropTypes.object,
  setModalPreview: PropTypes.func,
}

export default ModalPreviewDocument
