import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input,
  Button, Upload, Select,
  Col, Row,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
}

const FormClaimMeninggalDunia = ({
  isSubmit, form, detail,
  listBank, listRelationship, listClaimType,
  documentList, handleChangeDocument,
  handleSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <div className="form-claim-by-admin">
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Row className="pb-3" type="flex" justify="space-around">
          <Col xs={24} sm={15}>
            <Card className="mb-4">
              <h5 className="mb-4">Informasi Data Pemohon</h5>
              <Form.Item label="Nomor Peserta :">
                {getFieldDecorator('registered_number', {
                  initialValue: detail.data.account ? detail.data.account.registered_number : '',
                  rules: [{ required: true, message: 'Nomor Peserta wajib diisi' }],
                })(
                  <Input
                    name="registered_number"
                    type="text"
                    placeholder="Masukan Nomor Peserta"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault()
                      }
                    }}
                  />,
                )}
              </Form.Item>
              <Form.Item label="Jenis Klaim">
                {getFieldDecorator('claim_type_id', {
                  initialValue: detail.data.claim_type_id,
                  rules: [{ required: true, message: 'Tipe klaim wajib diisi' }],
                })(
                  <Select
                    loading={listClaimType.isFetching}
                    placeholder="Pilih Jenis Klaim"
                  >
                    {listClaimType.data.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label="Nama Pemohon :">
                {getFieldDecorator('claimant_name', {
                  initialValue: detail.data.claimant_name,
                  rules: [{ required: true, message: 'Nama pemohon wajib diisi' }],
                })(
                  <Input
                    name="claimant_name"
                    type="text"
                    autoComplete="off"
                    placeholder="Masukan Nama pemohon"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Email Pemohon :">
                {getFieldDecorator('claimant_email', {
                  initialValue: detail.data.claimant_email,
                  rules: [
                    { required: true, message: 'Email wajib diisi' },
                    { type: 'email', message: 'Format Email tidak valid' },
                  ],
                })(
                  <Input
                    name="claimant_email"
                    type="text"
                    placeholder="Masukan Email pemohon"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Hubungan :">
                {getFieldDecorator('relation_type_id', {
                  initialValue: detail.data.relation_type_id,
                  rules: [{ required: true, message: 'Hubungan pemohon wajib diisi' }],
                })(
                  <Select
                    loading={listRelationship.isFetching}
                    placeholder="Pilih Hubungan"
                  >
                    {listRelationship.data.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                  </Select>,
                )}
              </Form.Item>
            </Card>
            <Card className="mb-4">
              <h5 className="mb-4">Data Penerima Manfaat</h5>
              <Form.Item label="Nama Lengkap :">
                {getFieldDecorator('recipient_name', {
                  initialValue: detail?.data?.recipient_name,
                  rules: [{ required: true, message: 'Nama penerima klaim wajib diisi' }],
                })(
                  <Input
                    name="recipient_name"
                    type="text"
                    autoComplete="off"
                    placeholder="Masukan Nama"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Bank :">
                {getFieldDecorator('bank_id', {
                  initialValue: detail?.data?.bank ? detail?.data?.bank?.id : null,
                  rules: [{ required: true, message: 'Bank penerima klaim wajib diisi' }],
                })(
                  <Select
                    loading={listBank.isFetching}
                    placeholder="Pilih Bank"
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  >
                    {listBank.data.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                  </Select>
                  ,
                )}
              </Form.Item>
              <Form.Item label="Nomor Rekening :">
                {getFieldDecorator('bank_account_number', {
                  initialValue: detail?.data?.bank_account_number,
                  rules: [{ required: true, message: 'Nomor rekening penerima klaim wajib diisi' }],
                })(
                  <Input
                    name="bank_account_number"
                    type="text"
                    placeholder="Masukan Nomor Rekening"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault()
                      }
                    }}
                  />,
                )}
              </Form.Item>
              <Form.Item label="Email :">
                {getFieldDecorator('recipient_email', {
                  initialValue: detail?.data?.recipient_email,
                  rules: [
                    { required: true, message: 'Email wajib diisi' },
                    { type: 'email', message: 'Format Email tidak valid' },
                  ],
                })(
                  <Input name="recipient_email" placeholder="Masukan Email" />,
                )}
              </Form.Item>

              <div className="d-flex justify-content-end">
                <Button
                  className="button-second-primary"
                  onClick={() => history.push('/claim/claim-by-admin')}
                >
                  Kembali
                </Button>
                <Button
                  loading={isSubmit}
                  className="button-primary ml-2"
                  htmlType="submit"
                >
                  Tambah Data
                </Button>
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={8}>
            <Card>
              <h5 className="mb-3">Dokumen Persyaratan</h5>
              <Upload
                disabled={isSubmit}
                fileList={documentList}
                name="document"
                listType="picture"
                beforeUpload={() => false}
                onChange={e => handleChangeDocument(e)}
                accept="image/*,.pdf"
              >
                <Button
                  loading={isSubmit}
                  className="button-primary"
                >
                  Upload Dokumen Persyaratan Disini
                </Button>
              </Upload>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

FormClaimMeninggalDunia.propTypes = {
  isSubmit: PropTypes.bool,
  isEdit: PropTypes.bool,
  detail: PropTypes.object,
  form: PropTypes.any,
  documentList: PropTypes.array,
  listBank: PropTypes.object,
  listRelationship: PropTypes.object,
  listClaimType: PropTypes.object,
  handleChangeDocument: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export default Form.create({ name: 'claimMeninggalDunia' })(FormClaimMeninggalDunia)
