import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Card, Button, Input,
  Icon, Tag, Alert,
  Row, Col, Skeleton,
  Checkbox, Tooltip,
} from 'antd'
import history from 'utils/history'
import ModalPreviewDocument from '../modalPreview'
import ModalConfirmClaim from '../modalConfirm'
import ModalRejectClaim from '../modalRejectClaim'

const ClaimNormalDetail = ({
  detailClaim, statusClaim, isSubmit,
  modalPreview, setModalPreview,
  scrollToTop, handleDownload,
  setModalPaymentDocument,
  // uploadModal, previewFile,
  // isUploading, handleChangeFile, handleUpload, fileName,
  isExport, handleDownloadClaimDoc,
  documentList,
  handleValidateDocumentClaim, handleUpdateStatusClaim,
  handleUpdateRollbackClaim,
  handleChangeDocumentStatus, handleChangeReason,
  modalConfirmClaim, setModalConfirmClaim,
  rejectClaimModal, setRejectClaimModal,
  handleChangeRejectNotes, handleFullRejectClaim,
}) => {
  const naik = useRef(null)
  if (scrollToTop) {
    naik.current.scrollIntoView({ behavior: 'smooth' })
  }
  let tagColor
  switch (detailClaim?.data?.status && detailClaim?.data?.status?.name) {
    case 'document review':
      tagColor = 'orange'
      break
    case 'need review':
      tagColor = 'blue'
      break
    case 'approved':
      tagColor = 'green'
      break
    case 'rejected':
      tagColor = 'red'
      break
    case 'full rejection':
      tagColor = 'red'
      break
    case 'administration':
      tagColor = 'orange'
      break
    case 'fund transferred':
      tagColor = 'green'
      break
    default:
      tagColor = ''
  }

  let alertStatus
  if (statusClaim && statusClaim.name === 'rejected') {
    alertStatus = {
      message: 'Permohonan dokumen telah ditolak, Form pengajaun dapat direview kembali setelah peserta sudah memperbaharui dokumen yang tidak sesuai',
      type: 'error',
    }
  } else if (statusClaim && statusClaim.name === 'approved') {
    alertStatus = {
      message: "Dokumen telah lengkap dan sudah valid, klik 'Simpan' untuk menyetujui permohonan klaim",
      type: 'info',
    }
  } else if (statusClaim && statusClaim.name === 'administration') {
    alertStatus = {
      message: "Permohonan klaim telah disetujui, klik 'Proses Pengiriman Dana' untuk melakukan Transfer",
      type: 'info',
    }
  } else if (statusClaim && statusClaim.name === 'rejected') {
    alertStatus = {
      message: detailClaim.data.reviewer && detailClaim.data.reviewer.review_notes ? detailClaim.data.reviewer.review_notes : 'Rejected',
      type: 'error',
    }
  } else if (statusClaim && statusClaim.name === 'full rejection') {
    alertStatus = {
      message: detailClaim.data.reviewer && detailClaim.data.reviewer.review_notes ? detailClaim.data.reviewer.review_notes : 'Full Rejected',
      type: 'error',
    }
  }
  return (
    <div className="pb-5">
      <div ref={naik} />
      <div className="mb-2">
        {alertStatus && (
          <Alert
            message={alertStatus.message || ''}
            type={alertStatus.type || ''}
            closable
          />
        )}
      </div>
      <div className="d-flex justify-content-end align-items-center">
        {statusClaim && (statusClaim?.name?.toUpperCase() === 'INQUIRY' || statusClaim?.name?.toUpperCase() === 'NEED REVIEW') && (
        <div>
          <Button
            loading={isSubmit}
            className="button-primary"
            onClick={() => setModalConfirmClaim({ isOpen: true, type: 'status', status: 'document review' })}
          >
            Lihat Klaim
          </Button>
        </div>
        )}
        {statusClaim && (statusClaim?.name?.toUpperCase() !== 'INQUIRY' || statusClaim?.name?.toUpperCase() !== 'NEED REVIEW') && (
          <div>
            <Button
              loading={isExport}
              className="button-primary ml-2"
              onClick={() => handleDownloadClaimDoc(detailClaim?.data?.account_name || '')}
            >
              Unduh Dokumen Klaim
            </Button>
          </div>
        )}
      </div>

      <div className="custom-card-wrapper mt-3">
        <Card bordered={false}>
          <h6 className="fw-bold mb-3" style={{ fontSize: '20px' }}>
            Data Kepesertaan
          </h6>
          {detailClaim?.isLoading ? (
            <Row>
              <Col>
                <Skeleton active />
              </Col>
            </Row>
          ) : (
            <Row gutter={[24, 16]}>
              <Col span={8}>
                <h6 className="mb-2">Nama Lengkap</h6>
                <p>{detailClaim?.data?.name || '-'}</p>
              </Col>
              <Col span={8}>
                <h6 className="mb-2">Nomor Ponsel</h6>
                <p>{detailClaim?.data?.phone_number || '-'}</p>
              </Col>
              <Col span={8}>
                <h6 className="mb-2">Status Klaim</h6>
                <p>
                  <Tag
                    color={tagColor}
                    style={{ fontSize: '1rem' }}
                  >
                    {statusClaim && statusClaim.status_label ? statusClaim.status_label : '-'}
                  </Tag>
                </p>
              </Col>
              <Col span={8}>
                <h6 className="mb-2">Nomor Peserta</h6>
                <p>{detailClaim?.data?.registered_number || '-'}</p>
              </Col>
              <Col span={8}>
                <h6 className="mb-2">Alamat Email</h6>
                <p>{detailClaim?.data?.email || '-'}</p>
              </Col>
              <Col span={8}>
                <h6 className="mb-2">Bukti Transfer</h6>
                <p>
                  {statusClaim && statusClaim?.name?.toUpperCase() !== 'INQUIRY' && (
                  <Button onClick={() => setModalPaymentDocument(true)}>
                    {detailClaim?.data?.payment_document ? 'View' : 'Upload'}
                  </Button>
                  )}
                </p>
              </Col>
              <Col span={8}>
                <h6 className="mb-2">Nama Peserta</h6>
                <p>{detailClaim?.data?.account_name || '-'}</p>
              </Col>
              <Col span={8}>
                <h6 className="mb-2">Tanggal Bergabung</h6>
                <p>{detailClaim?.data?.join_date || '-'}</p>
              </Col>
            </Row>
          )}
        </Card>
      </div>

      <div className="custom-card-wrapper mt-4">
        <Card bordered={false}>
          <h6 className="fw-bold mb-3" style={{ fontSize: '20px' }}>
            Data Pemohon
          </h6>
          {detailClaim?.isLoading ? (
            <Row>
              <Col>
                <Skeleton active />
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Row>
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Jenis Klaim Manfaat</h6>
                    </Col>
                    <Col span={12}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.claim_type ? detailClaim?.data?.claim_type?.name : '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Nama Lengkap</h6>
                    </Col>
                    <Col span={12}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.name || '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Hubungan</h6>
                    </Col>
                    <Col span={12}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.relation_type ? detailClaim?.data?.relation_type?.name : '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Bank</h6>
                    </Col>
                    <Col span={12}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.bank ? detailClaim?.data?.bank?.bank_name : '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Nomor Rekening</h6>
                    </Col>
                    <Col span={12}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.bank ? detailClaim?.data?.bank?.bank_account_no : '-'}
                      </p>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row>
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Tempat Klaim</h6>
                    </Col>
                    <Col span={12}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.signature_place || '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Tanggal Klaim</h6>
                    </Col>
                    <Col span={12}>
                      <p className="fs-16 fw-bold text-black ml-2 mb-0">
                        <span className="mr-2">:</span>
                        {detailClaim?.data?.signature_date || '-'}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col span={8}>
                      <h6 className="fs-16 font-grey pt-1 mb-0">Tanda Tangan Digital</h6>
                    </Col>
                    <Col span={12}>
                      <div className="d-flex">
                        <span className="fs-16 fw-bold text-black ml-1">:</span>
                        <Button
                          className="button-second-primary d-flex align-items-center ml-2"
                          onClick={() => setModalPreview({ isOpen: true, data: detailClaim?.data?.signature_image || '' })}
                        >
                          <Icon type="eye" className="mr-1" />
                          Lihat Dokumen
                        </Button>

                        <a
                          href={detailClaim.data.signature_image}
                          target="_blank"
                          download={detailClaim?.data?.signature_image ? detailClaim?.data?.signature_image.slice(detailClaim?.data?.signature_image.lastIndexOf('/') + 1) : 'file'}
                          className="button-primary-link d-flex align-items-center ml-2"
                          rel="noreferrer"
                        >
                          <Icon type="download" className="mr-1" />
                          Download
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Card>
      </div>

      <div className="document-support custom-card-wrapper mt-4">
        <Card bordered={false}>
          <h6 className="fw-bold mb-3" style={{ fontSize: '20px' }}>
            Dokumen Pendukung
          </h6>
          {detailClaim?.isLoading ? (
            <Row>
              <Col>
                <Skeleton active />
              </Col>
            </Row>
          ) : (
            <Row gutter={[16, 32]}>
              {(documentList || []).map((item, index) => (
                <Col span={12} key={item.id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      {statusClaim
                        && (statusClaim?.name?.toUpperCase() === 'INQUIRY'
                        || statusClaim?.name?.toUpperCase() === 'DOCUMENT REVIEW'
                        || statusClaim?.name?.toUpperCase() === 'NEED REVIEW') && (
                        <div>
                          {item.status?.toUpperCase() === 'INQUIRY' ? (
                            <React.Fragment>
                              <Tooltip placement="right" title="Check jika dokumen sesuai">
                                <Checkbox
                                  id={item?.id}
                                  name={item?.id}
                                  onChange={e => handleChangeDocumentStatus(e, index)}
                                />
                              </Tooltip>
                            </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Icon
                                    type={`${item.status?.toUpperCase() === 'APPROVED' ? 'check-circle' : 'close-circle'}`}
                                    theme="twoTone"
                                    twoToneColor={`${item.status?.toUpperCase() === 'APPROVED' ? '#52c41a' : '#f5222d'}`}
                                    style={{ fontSize: '22px' }}
                                  />
                                </React.Fragment>
                              )}
                        </div>
                      )}
                      {statusClaim
                        && (statusClaim?.name?.toUpperCase() === 'APPROVED'
                        || statusClaim?.name?.toUpperCase() === 'REJECTED'
                        || statusClaim?.name?.toUpperCase() === 'FULL REJECTION'
                        || statusClaim?.name?.toUpperCase() === 'ADMINISTRATION'
                        || statusClaim?.name?.toUpperCase() === 'FUND TRANSFERRED')
                        && (
                        <div>
                          <Icon
                            type={`${item.status?.toUpperCase() === 'APPROVED' ? 'check-circle' : 'close-circle'}`}
                            theme="twoTone"
                            twoToneColor={`${item.status?.toUpperCase() === 'APPROVED' ? '#52c41a' : '#f5222d'}`}
                            style={{ fontSize: '22px' }}
                          />
                        </div>
                        )}
                      <div>
                        <h6 className={`${item.type === 'ktp' || item.type === 'npwp' ? 'text-uppercase' : 'text-capitalize'} ml-2 mb-0`}>
                          {item?.type || '-'}
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex">
                      <div>
                        <Button
                          className="button-second-primary d-flex align-items-center"
                          onClick={() => setModalPreview({ isOpen: true, data: item.url || '' })}
                        >
                          <Icon type="eye" className="mr-1" />
                          Lihat Dokumen
                        </Button>
                      </div>
                      <div className="ml-2">
                        <Button
                          className="button-primary d-flex align-items-center"
                          onClick={() => handleDownload(item.url)}
                        >
                          <Icon type="download" className="mr-1" />
                          Download
                        </Button>
                      </div>
                    </div>
                  </div>

                  {(!item?.is_approved) && (
                    <div className="textarea-custom mt-3">
                      <Input.TextArea
                        id={item.id}
                        name={item.id}
                        defaultValue={(item.reason || '')}
                        disabled={!((statusClaim?.name?.toUpperCase() === 'INQUIRY' || statusClaim?.name?.toUpperCase() === 'DOCUMENT REVIEW'))}
                        onChange={e => handleChangeReason(e, index)}
                      />
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          )}
        </Card>
      </div>

      <div className="approval-container w-100 mt-3">
        <div className="d-flex justify-content-end">
          <div className="d-flex justify-content-between">
            <Button
              className="button-second-primary mr-1"
              onClick={() => history.push('/claim/claim-normal')}
            >
              Kembali
            </Button>

            {statusClaim
              && (statusClaim?.name?.toUpperCase() === 'INQUIRY'
              || statusClaim?.name?.toUpperCase() === 'DOCUMENT REVIEW'
              || statusClaim?.name?.toUpperCase() === 'NEED REVIEW'
              || statusClaim?.name?.toUpperCase() === 'APPROVED'
              || statusClaim?.name?.toUpperCase() === 'ADMINISTRATION')
              && (
                <Button
                  className="button-danger ml-2"
                  onClick={() => setRejectClaimModal(true)}
                >
                  Batalkan Klaim
                </Button>
              )}
            {statusClaim
              && (statusClaim?.name?.toUpperCase() === 'APPROVED'
              || statusClaim?.name?.toUpperCase() === 'ADMINISTRATION')
              && (
                <Button
                  className="button-custom btn-yellow ml-2"
                  style={{ color: '#000' }}
                  onClick={() => setModalConfirmClaim({ isOpen: true, type: 'rollback', status: '' })}
                >
                  Pengembalian Status Klaim
                </Button>
              )}
            {(statusClaim && statusClaim?.name?.toUpperCase() === 'DOCUMENT REVIEW')
              && (
                <Button
                  className="button-primary ml-2"
                  onClick={() => setModalConfirmClaim({ isOpen: true, type: 'validation', status: '' })}
                >
                  Simpan
                </Button>
              )
            }
            {(statusClaim && statusClaim?.name?.toUpperCase() === 'APPROVED')
              && (
              <Button
                className="button-custom btn-green ml-2"
                onClick={() => setModalConfirmClaim({ isOpen: true, type: 'status', status: 'administration' })}
              >
                Lanjut Proses Administrasi
              </Button>
              )
            }
            {(statusClaim && statusClaim?.name?.toUpperCase() === 'ADMINISTRATION')
              && (
              <Button
                className="button-custom btn-green ml-2"
                onClick={() => setModalConfirmClaim({ isOpen: true, type: 'status', status: 'fund transferred' })}
              >
                Proses Pengiriman Dana
              </Button>
              )
            }
          </div>
        </div>
      </div>

      {/* Modal Confirm Klaim */}
      <ModalConfirmClaim
        isSubmit={isSubmit}
        modalConfirm={modalConfirmClaim}
        setModalConfirm={setModalConfirmClaim}
        handleValidateDocumentClaim={handleValidateDocumentClaim}
        handleUpdateRollbackClaim={handleUpdateRollbackClaim}
        handleUpdateStatusClaim={handleUpdateStatusClaim}
      />

      {/* Modal Reject Klaim */}
      <ModalRejectClaim
        isSubmit={isSubmit}
        rejectClaimModal={rejectClaimModal}
        setRejectClaimModal={setRejectClaimModal}
        handleChangeRejectNotes={handleChangeRejectNotes}
        handleFullRejectClaim={handleFullRejectClaim}
      />

      {/* Modal Preview Dokumen */}
      <ModalPreviewDocument
        modalPreview={modalPreview}
        setModalPreview={setModalPreview}
      />
    </div>
  )
}

ClaimNormalDetail.propTypes = {
  isSubmit: PropTypes.bool,
  isExport: PropTypes.bool,
  documentList: PropTypes.array,
  statusClaim: PropTypes.object,
  detailClaim: PropTypes.object,
  modalPreview: PropTypes.object,
  setModalPreview: PropTypes.func,
  scrollToTop: PropTypes.bool,
  handleDownload: PropTypes.func,
  setModalPaymentDocument: PropTypes.func,
  // uploadModal: PropTypes.bool,
  // handleUpload: PropTypes.func,
  // previewFile: PropTypes.any,
  // isUploading: PropTypes.bool,
  // handleChangeFile: PropTypes.func,
  // fileName: PropTypes.string,
  handleChangeDocumentStatus: PropTypes.func,
  handleChangeReason: PropTypes.func,
  handleValidateDocumentClaim: PropTypes.func,
  handleUpdateRollbackClaim: PropTypes.func,
  handleUpdateStatusClaim: PropTypes.func,
  handleDownloadClaimDoc: PropTypes.func,
  modalConfirmClaim: PropTypes.object,
  setModalConfirmClaim: PropTypes.func,
  rejectClaimModal: PropTypes.bool,
  setRejectClaimModal: PropTypes.func,
  handleChangeRejectNotes: PropTypes.func,
  handleFullRejectClaim: PropTypes.func,
}

export default ClaimNormalDetail
