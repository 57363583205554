import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal, Button,
} from 'antd'

const ModalConfirmClaim = ({
  isSubmit, modalConfirm, setModalConfirm,
  handleValidateDocumentClaim,
  handleUpdateRollbackClaim, handleUpdateStatusClaim,
}) => (
  <div>
    <Modal
      centered
      width={600}
      visible={modalConfirm?.isOpen}
      footer={null}
      closable={false}
    >
      <div>
        <div className="text-center">
          <h4 style={{ fontWeight: 600, color: '#1b4db3' }}>
            Konfirmasi
          </h4>
          <div style={{ fontSize: 16, color: '#000' }}>
            <p className="mb-0">Apakah Anda yakin ingin melanjutkan proses ini?</p>
            <p>Mohon pastikan bahwa seluruh data telah diperiksa dengan cermat dan sesuai setiap perubahan yang dilakukan dapat memengaruhi tahapan proses selanjutnya</p>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <div className="d-flex">
            <Button
              className="button-second-primary"
              onClick={() => setModalConfirm({ isOpen: false, type: '', status: '' })}
            >
              Batal
            </Button>

            {modalConfirm.type === 'validation' && (
              <Button
                loading={isSubmit}
                className="button-primary ml-2"
                onClick={() => handleValidateDocumentClaim()}
              >
                Ya, Lanjutkan
              </Button>
            )}

            {modalConfirm.type === 'status' && (
              <Button
                loading={isSubmit}
                className="button-primary ml-2"
                onClick={() => handleUpdateStatusClaim(modalConfirm?.status)}
              >
                Ya, Lanjutkan
              </Button>
            )}

            {modalConfirm.type === 'rollback' && (
              <Button
                loading={isSubmit}
                className="button-primary ml-2"
                onClick={() => handleUpdateRollbackClaim()}
              >
                Ya, Lanjutkan
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  </div>
)

ModalConfirmClaim.propTypes = {
  isSubmit: PropTypes.bool,
  modalConfirm: PropTypes.object,
  setModalConfirm: PropTypes.func,
  handleValidateDocumentClaim: PropTypes.func,
  handleUpdateRollbackClaim: PropTypes.func,
  handleUpdateStatusClaim: PropTypes.func,
}

export default ModalConfirmClaim
