/* eslint-disable array-callback-return */
import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchListBank } from 'actions/Bank'
import {
  fetchClaimRelationship, fetchClaimTypeForm, createClaim, fetchDetailClaimCMS,
} from 'actions/Claim'
import FormClaimMeninggalDunia from 'components/pages/claim/claimMeninggalDunia/Form'
import FormData from 'form-data'
import { message } from 'antd'
import history from 'utils/history'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth
  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchListBank: bindActionCreators(fetchListBank, dispatch),
  fetchClaimRelationship: bindActionCreators(fetchClaimRelationship, dispatch),
  fetchClaimTypeForm: bindActionCreators(fetchClaimTypeForm, dispatch),
  createClaim: bindActionCreators(createClaim, dispatch),
  fetchDetailClaimCMS: bindActionCreators(fetchDetailClaimCMS, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmit', 'setIsSubmit', false),
  withState('documentList', 'setDocumentList', []),
  withState('detail', 'setDetail', {
    meta: {},
    data: [],
    isFetching: false,
  }),
  withState('listBank', 'setListBank', {
    data: [],
    isFetching: true,
  }),
  withState('listRelationship', 'setListRelationship', {
    data: [],
    isFetching: true,
  }),
  withState('listClaimType', 'setListClaimType', {
    data: [],
    isFetching: true,
  }),
  withHandlers({
    getListBank: props => () => {
      const {
        setListBank,
      } = props
      props.fetchListBank()
        .then((result) => {
          setListBank({
            data: result.data,
            isFetching: false,
          })
        })
        .catch(() => {
          setListBank({
            data: [],
            isFetching: false,
          })
        })
    },
    getListRelationship: props => () => {
      const {
        setListRelationship,
      } = props
      props.fetchClaimRelationship()
        .then((result) => {
          setListRelationship({
            data: result.data,
            isFetching: false,
          })
        })
        .catch(() => {
          setListRelationship({
            data: [],
            isFetching: false,
          })
        })
    },
    getListClaimType: props => () => {
      const {
        setListClaimType,
      } = props
      props.fetchClaimTypeForm()
        .then((result) => {
          setListClaimType({
            data: result.data,
            isFetching: false,
          })
        })
        .catch(() => {
          setListClaimType({
            data: [],
            isFetching: false,
          })
        })
    },
    getDetail: props => (params) => {
      const { match, setDetail } = props
      props.fetchDetailClaimCMS(match.params.id, params)
        .then((result) => {
          setDetail({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setDetail({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    handleChangeDocument: props => (file) => {
      const fileList = [...file.fileList]
      props.setDocumentList(fileList)
    },
    handleSubmit: props => async (values) => {
      const { setIsSubmit, documentList } = props
      const form = new FormData()
      form.append('bank_account_number', values?.bank_account_number || '')
      form.append('bank_id', values?.bank_id || '')
      form.append('claim_type_id', values?.claim_type_id || '')
      form.append('claimant_email', values?.claimant_email || '')
      form.append('claimant_name', values?.claimant_name || '')
      form.append('recipient_email', values?.recipient_email || '')
      form.append('recipient_name', values?.recipient_name || '')
      form.append('registered_number', values?.registered_number || '')
      form.append('relation_type_id', values?.relation_type_id || '')
      documentList.forEach((item) => {
        form.append('documents', item.originFileObj || '')
      })
      if (documentList?.length < 1) {
        message.error('Dokumen Persyaratan wajib dilampirkan')
      } else {
        setIsSubmit(true)
        props.createClaim(form)
          .then(() => {
            setIsSubmit(false)
            message.success('Klaim Berhasil Dibuat')
            history.push('/claim/claim-by-admin')
          })
          .catch(() => {
            setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Klaim', 'Tambah Klaim'])
      this.props.updateSiteConfiguration('titlePage', 'Tambah Data Klaim')
      this.props.getListBank()
      this.props.getListRelationship()
      this.props.getListClaimType()
      if (match?.params?.id) {
        setIsEdit(true)
        this.props.getDetail()
      }
    },
  }),
)(FormClaimMeninggalDunia)
